<template>
  <div class="stuff-search flex">
    <div class="dmest">
      <div class="chd flex row-between">
        <div class="search flex">
          <input type="text" placeholder="请输入课程名称搜索" class="searchInput" v-model="text" @keydown.enter="$throttle(search, 1000)" />
          <div class="searchIcon" @click="search"><img src="../../assets/img/search.png" ></div>
          <div class="del" v-if="showDel" @click="onClear"><img src="../../assets/img/img28.png" ></div>
        </div>
      </div>
      <template v-if="focus">
        <div class="flex row-between ptb10 col-top myPost">
          <div>
            <div class="tap pr flex flex-wrap f18">
              <div class="item cup mb10" :class="{active: classCurrent == index}" v-for="(item, index) in classifyList" :key="index" @click="tapClass(item, index)">{{item.name}}</div>
            </div>
            <div class="tap flex pt10 f16 ml65" v-if="brother.length">
              <div class="item cup" :class="{active: brothCurrent == index}" v-for="(item, index) in brother" :key="index" @click="tapBroth(item, index)">{{item.name}}</div>
            </div>
          </div>
          <div class="them f14 c333 flex-1 text-right" v-if="subtitle"><span>{{subtitle}}</span></div>
        </div>
        <div class="scrollBox" v-if="child.length">
          <div id="classifyScroll" class="flex">
            <div class="classifys">
              <div class="leftIcon cup" @click="leftClick"><img src="../../assets/img/img22.png" ></div>
              <div class="rightIcon cup" @click="rightClick"><img src="../../assets/img/img23.png" ></div>
              <div class="classifys-item cup" :class="{active: childCurrent == index,typeClass: type=='SPECIAL'}" v-for="(item, index) in child" :key="index" :ref="`item${index}`" @click="$throttle(() => {tapChild(item, index)}, 1000)">
                {{ item.name }}
                <p v-if="subtitle2 && childCurrent == index">周主题：<span>{{subtitle2}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="list mt20" ref="container">
        <template v-for="(item, index) in list">
          <template v-if="item.child.length">
            <div class="weekDay f26 c333 text-left pb10 pt30 pl10" :key="'i'+index" v-if="item.name">{{item.name}}</div>
            <course-list v-for="dt in item.child" :key="dt.id" :item="dt"></course-list>
          </template>
        </template>
        <div class="noMsg" style="margin-top:50px" v-if="!list.length && noMsgShow">
          <div class="img"><img src="../../assets/img/courseMsg.jpg" ></div>
          <div class="text-center f24 c333 pt10" v-if="childCurrent != -1">查询无结果</div>
          <div class="text-center f24 c333 pt10" v-else>请选择筛选条件</div>
        </div>
      </div>
      
      <template v-if="guideShow">
        <div class="mask"></div>
        <div class="guide">
          <div class="base">
            <img src="../../assets/img/img25.png" class="img1">
            <img src="../../assets/img/img26.png" class="img2 cup" @click="guide">
          </div>
        </div>
      </template>
    </div>
    <investigation></investigation>
    
    
    <modal v-if="false"></modal>
  </div>
</template>

<script>
  import investigation from '../../components/investigation.vue';
  import modal from '../../components/modal.vue';
  import courseList from '../../components/course-list.vue'
  import { getItem, setItem } from '../../assets/js/storage.js';
  import { Scroll } from '@/utils/loading'
  export default{
    components:{
      modal,
      courseList,
      investigation
    },
    data(){
      return {
        id: '',
        type: 'NORMAL',
        text: '',
        showDel: false,
        // showRightIcon: true, // 是否显示右箭头
        maxClickNum: 0, // 最大点击次数
        lastLeft: 0, // 上次滑动距离
        clickNum: 0, // 点击次数
        
        
        guideShow: false,
        focus: true,
        
        classifyList: [],
        classCurrent: -1,
        brother: [],
        brothCurrent: -1,
        child: [],
        childCurrent: -1,
        lastChild: [],
        cateId: '',
        subtitle: '',
        subtitle2: '',
        noMsgShow: false,
        
        list2: [],
        page: 1,
        list: [],
        length: 0,

        frist: true,
        loading: false,
      }
    },
    watch:{
      'word'(oldVal){
        if(oldVal){
          this.showDel = true;
        }else{
          this.showDel = false;
        }
      }
    },
    mounted() {
      this.id = this.$route.query.id;
      this.subtitle = this.$route.query.keyword;
      if(!getItem('modal')) this.guideShow = true;
      this.getClass();

      // 下拉加载 
      this.$scroll(this.$refs.container, () => {
        if (this.length > this.list2.length) {
          !this.loading && this.getList()
        }
      })
      Scroll.listenTouchDirection()
    },
    methods:{
      // 向右滚动
      rightClick() {
        if (this.clickNum < this.child.length - 1) {
          let width = (document.querySelectorAll('.classifys-item')[this.clickNum].offsetWidth + 10) * 2;
          let lastItemOffsetLeft = document.getElementsByClassName('classifys-item')[this.child.length - 1].offsetLeft;
          const lookWidth = document.getElementById('classifyScroll').clientWidth;
          if (lastItemOffsetLeft > lookWidth) {
            document.getElementsByClassName('classifys')[0].style.marginLeft = `${-width + this.lastLeft}px`;
            this.lastLeft = -width + this.lastLeft;
            this.clickNum += 1;
            this.maxClickNum = this.clickNum;
          }
          // this.showRightIcon = lastItemOffsetLeft > lookWidth + width;
        }
      },
      // 向左滚动
      leftClick() {
        if (this.clickNum > 0) {
          let width = (document.querySelectorAll('.classifys-item')[this.clickNum - 1].offsetWidth + 10) * 2;
          document.getElementsByClassName('classifys')[0].style.marginLeft = `${this.lastLeft + width}px`;
          this.lastLeft = width + this.lastLeft;
          this.clickNum--;
          // if (this.clickNum < this.maxClickNum) {
          //   this.showRightIcon = true;
          // }
        }
      },
      async getList(){
        this.noMsgShow = false;
        this.loading = true
        var params = {
          page: this.page,
          pagesize: 15,
          sujectId: this.id,
          cateId: this.cateId,
        }
        var res = await this.$api.get('/api/project_course/courseListBySuject', params);
        if(res.code == 0){
          var data = res.data.list;
          this.list2 = [...this.list2, ...data];
          this.length = res.data.count;
          this.setList();
          this.loading = false;
        }
        this.noMsgShow = true;
        this.page = this.page + 1;
      },
      setList(){
        if(this.list2.length == 0) return this.list = [];
        
        var arr = this.sortArr( this.list2, 'cateId');
        var list = [];
        
        var lastChild = this.lastChild;
        var weeks = ['星期一','星期二','星期三','星期四','星期五','星期六','星期日'];
        console.log(lastChild)
        // 设置name
        for (var i = 0; i < arr.length; i++) {
          var index = Number(arr[i][0].cateName) - 1;
          var str = '';
          if(lastChild.length){
            str = lastChild[index].subtitle;
          }
          var obj = {
            name: str,
            // name: weeks[index],
            child: arr[i]
          }
          list = [...list, obj];
        }
        this.list = list;
        // console.log(list);
      },
      // 重新整理res.data的数据
      sortArr(arr, str) {
        var _arr = [],
          _t = [],
          _tmp;
     
        if ( arr.length ){
          _tmp = arr[0][str];
        }
        for (var i in arr) {
          if ( arr[i][str] === _tmp ){
            _t = [..._t, arr[i]]
          } else {
            _tmp = arr[i][str];
            _arr = [..._arr, _t];
            _t = [arr[i]];
          }
        }
        _arr = [..._arr, _t];
        return _arr;
      },
      // 获取分类
      async getClass(){
        var params = {
          sujectId: this.id
        }
        var res = await this.$api.get('/api/project_course/getCateTreeBySuject',params);
        if(res.code == 0){
          var data = res.data.list;
          
          // 设置默认‘所有’
          // res.data.list[0].child = [{id:0,name: '所有'}, ...res.data.list[0].child];
          data = [{id:0,name: '所有', type: 'SPECIAL'}, ...data]
          this.classifyList = data;
          // this.subtitle = res.data.list[0].name;
          // console.log(this.classifyList)

          // 进入页面后设置默认选择,如果不需要，去掉下面的代码即可
          this.tapClass(data[0], 0);
          this.type = data[0].type; // 获取当前分类是否为特殊分类
          if(data[0].type == 'SPECIAL'){ // 是特殊分类
            this.tapChild(data[0].child, 0);
          }else{ // 是普通分类
            this.tapBroth(data[0].child[0], 0);
          }

          // this.tapChild(data[0].child[0].child[0], 0);
        }
      },
      tapClass(item, index){
        this.list2 = [];
        this.list = [];
        this.page = 1;
        this.classCurrent = index;
        this.type = item.type;
        if(item.id == 0){
          item.child = [];
        }
        
        // 特殊分类
        // if(item.type == 'SPECIAL'){
        //   this.brother = [];
        //   this.lastChild = [];
        //   this.child = item.child;
        //   this.childCurrent = -1;
        //   this.cateId = item.id;
        //   // return this.getList();
        // }else{ // 普通分类
          this.brother = item.child;
          this.brothCurrent = -1;
          this.child = [];
          this.childCurrent = -1;
          this.cateId = item.id;
        // }
        // 是否首次进入页面
        if(this.frist){
          this.frist = false;
          return;
        }
        this.getList();
      },
      tapBroth(item, index){
        this.list2 = [];
        this.list = [];
        this.page = 1;
        this.brothCurrent = index;
        if(item.child) this.child = item.child;
        
        this.childCurrent = -1;
        this.cateId = item.id;
        this.getList();
      },
      tapChild(item, index){
        this.childCurrent = index;
        if(item){
          this.subtitle2 = item.subtitle;
          this.cateId = item.id;
        }
        if(this.type == 'SPECIAL'){
          this.lastChild = item;
        }else{
          this.lastChild = item.child;
        }
        this.list = [];
        this.list2 = [];
        this.page = 1;
        // console.log(item)
        // console.log(this.lastChild)
        this.getList();
      },
      // 开始搜索
      search(){
        if(!this.text) return this.$message.warning('关键词不能为空');
        this.$router.push({name: 'stuff-list', params: {value: this.text}});
      },
      // 输入框有焦点事件
      onFocus(){
        this.focus = false;
      },
      // 失去焦点事件
      onBlur(){
        if(this.text){
          this.focus = false;
        }else{
          this.focus = true;
        }
      },
      // 记录是否第一次进入此页面
      guide(){
        this.guideShow = false;
        setItem('modal', 1);
      },
      onClear(){
        this.text = '';
      }
    }
  }
</script>

<style lang="scss" scoped>
  .stuff-search{
    height: 100%;
    .dmest{
      width: 71%;
      height: 100%;
      padding-top: 50px;
      padding-left: 40px;
      overflow: hidden;
      .chd{
        height: 50px;
        .search{
          position: relative;
          overflow: hidden;
          width: 360px;
          height: 40px;
          background-color: #ecf1ff;
          border-radius: 6px;
          .del{
            position: absolute;
            top: 50%;
            right: 86px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            cursor: pointer;
            font-size: 0;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .searchIcon{
            position: relative;
            width: 67px;
            background-color: #d9e2fc;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            height: 50px;
            img{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 18px;
              height: 18px;
            }
          }
          .searchInput{
            background-color: inherit;
            border: 0;
            flex: 1;
            padding: 0 15px;
            font-size: 12px;
            color: #000034;
            &:-moz-placeholder{
              color: #95c2ea;
            }
            &::-moz-placeholder{
              color: #95c2ea;
            }
            &::-webkit-input-placeholder{
              color: #95c2ea;
            }
            &:-ms-input-placeholder{
              color: #95c2ea;
            }
          }
        }
      }
    }
    .course-list{
      overflow: auto;
      position: relative;
      width: 100%;
      height: calc(100% - 230px);
    }
    .listBox{
      max-height: calc(100% - 230px);
      overflow:hidden;
      position: relative;
      overflow-y: auto;
    }
    .list{
      overflow: auto;
      height: calc(100% - 210px);
      position: relative;
      z-index: 2;
      .weekDay{
        font-weight: bold;
      }
      .item{
        width: 98%;
        background-color: #ecf1ff;
        height: 80px;
        margin-bottom: 10px;
        border-radius: 10px;
        .rist{
          img{
            margin-left: 36px;
          }
        }
        .leet{
          height: 80px;
          .coures{
            color: #5b68fb;
            font-weight: bold;
          }
          .color{
            width: 54px;
            height: 54px;
            border-radius: 8px;
            position: relative;
            img{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
      }
    }
    .scrollBox{
      position: relative;
      background-color: #e1e7f5;
      height: 36px;
      border-radius: 36px;
      z-index: 2;
      #classifyScroll {
        overflow: hidden;
        transition: all 0.3s;
        width: 96%;
        height: 68px;
        margin: 0 auto;
        align-items: flex-start;
        &.active {
          transition: all 0.3s;
          margin: 0 auto;
        }
        .classifys {
          // overflow-x: scroll;
          // overflow-y: hidden;
          white-space: nowrap;
          scrollbar-width: none;
          -ms-overflow-style: none;
          transition: all 0.3s;
          height: 28px;
          font-size: 18px;
          margin-top: 4px;
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
          .rightIcon {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 11px;
            height: 19px;
          }
          .leftIcon {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 11px;
            height: 19px;
          }
          .classifys-item {
            display: inline-block;
            width: 28px;
            height: 28px;
            line-height: 28px;
            margin-right: 10px;
            color: #6672fb;
            position: relative;
            &.typeClass{
              font-size: 14px;
              border-radius: 8px !important;
              width: auto;
              padding: 0 10px;
            }
            p{
              position: absolute;
              font-size: 14px;
              left: 0;
              // transform: translateX(-50%);
              bottom: -30px;
              color: #000;
              span{
                color: #6672fb;
                // font-size: 26px;
              }
            }
            &.active{
              background-color: #6672fb;
              border-radius: 50%;
              position: relative;
              color: #FFFFFF;
              &::after{
                // content: '周';
                display: inline-block;
                position: absolute;
                left: 50%;
                bottom: -30px;
                transform: translateX(-50%);
                font-size: 16px;
                color: #333333;
              }
            }
          }
        }
      }
    }

    .scrollBox{
      width: 100%;
      height: 36px;
      border-radius: 36px;
      background-color: #e1e7f5;
      .scroll{
        width: 11px;
        height: 19px;
      }
      .week{
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        scrollbar-width: none;
        -ms-overflow-style: none;
        width: 1460px;
        height: 36px;
        line-height: 36px;
        color: #FFFFFF;
        font-size: 18px;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .link{
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          margin-right: 10px;
          &:last-child{
            margin-right: 0;
          }
          &.active{
            background-color: #6672fb;
            border-radius: 50%;
          }
        }
      }
    }
    .tap{
      .item{
        text-align: center;
        background-color: #ecf1ff;
        padding: 0 16px;
        height: 24px;
        border-radius: 24px;
        line-height: 24px;
        margin-right: 10px;
        color: #7fa0e5;
        font-size: 12px;
        &.active{
          background-color: #6672fb;
          color: #FFFFFF;
        }
      }
    }
    .them{
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 50px;
      span{
        font-weight: 400;
        font-size: 26px;
        color: #5b68fb;
        padding-left: 2px;
        max-width: 74%;
      }
    }
    .guide{
      position: absolute;
      // left: 520px;
      top: 108px;
      z-index: 3;
      .base{
        position: relative;
        .img2{
          position: absolute;
          bottom: -130px;
          right: -180px;
        }
      }
    }
    .mask{
      position: fixed;
      left: 0;
      top: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.5);
    }
  }
</style>
